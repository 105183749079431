<template>
  <Layout>
    <van-pull-refresh
      style="min-height: 100%;"
      pulling-text="Pull down to refresh..."
      loosing-text="Release to refresh..."
      loading-text="Loading..."
    >
      <div class="pagePay">
        <img src="../assets/waitUPI.png" alt="" />
        <h3>Waiting for payment...</h3>
        <p>Please login to your app and accept payment from Mpurse</p>
        <hr />
        <p>
          <span>Amount</span>
          <span>₹ {{ amount }}</span>
        </p>
        <p>
          <span>Payment Method</span>
          <span>Net Banking</span>
        </p>
      </div>
    </van-pull-refresh>
  </Layout>
</template>
<script setup>
import Layout from "@/layout/index.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";

import { pagePayPayment } from "@/api/payment";

let route = useRoute();
let router = useRouter();

// 交易数据
let amount = route.query.amount;


//发起支付
onMounted(() => getPagePay());

// 发起支付
let getPagePay = () => {
  pagePayPayment()
    .then((response) => {
      if (response.data && 200 === response.code) {
        window.location.href = response.data;
      } else {
        router.push({
          path: "/paymentResult",
          query: {
            result: "FAILURE",
            type: "Pay through Bank",
            amount: amount,
          },
        });
      }
    })
    .catch((error) => {
      router.push({
        path: "/paymentResult",
        query: {
          result: "FAILURE",
          type: "Pay through Bank",
          amount: amount,
        },
      });
    });
};
</script>
<style scoped lang="scss" type="text/scss">
.pagePay {
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    width: 25%;
  }

  h3 {
    margin: 5% 0;
  }

  p {
    font-size: 1rem;
    color: #666666;
    overflow: hidden;

    span:first-child {
      float: left;
      margin-bottom: 10%;
    }

    span:last-child {
      float: right;
      color: #333333;
    }
  }

  hr {
    margin: 8% 0;
    border: 1px solid #f5f5f5;
  }
}
</style>
